<template>
  <div class="container">
    <div class="top_container">
      <a-icon v-if="isBack" @click="$router.go(-1)" class="back_btn" type="left" />
      <span>售后进度</span>
    </div>
    <template v-if="!loading">
      <div class="desc_container">
        <div class="title">工单信息</div>
        <div class="desc_item">
          <div class="name">报修人</div>
          <div class="value">{{ order.linkman_name }}</div>
        </div>
        <div class="desc_item">
          <div class="name">报修电话</div>
          <div class="value">{{ order.linkman_mobile }}</div>
        </div>
        <div class="desc_item">
          <div class="name">故障类别</div>
          <div class="value">{{ order.big_fault_category ? order.big_fault_category.name : '' }}{{ order.small_fault_category ? '/' + order.small_fault_category.name : '' }}</div>
        </div>
        <div class="desc_item">
          <div class="name">故障描述</div>
          <div class="value">{{ order.content }}</div>
        </div>
        <div class="desc_item">
          <div class="name">工单状态</div>
          <div class="value">{{ order.status_name }}</div>
        </div>
        <div class="desc_item">
          <div class="name">报修时间</div>
          <div class="value">{{ order.created_at }}</div>
        </div>
      </div>
      <a-timeline class="timeline_container">
        <a-timeline-item v-for="log in logList" :key="log.id">
          <p>{{ log.operation_name }}</p>
          <p>{{ log.content }}</p>
          <p style="color: #999">{{ log.created_at }}</p>
        </a-timeline-item>
      </a-timeline>
    </template>
    <div class="loading" v-else><a-icon type="loading" /></div>
  </div>
</template>
<script>
import request from '@/utils/request';
export default {
  data () {
    return {
      loading: true,
      order: {},
      logList: [],
      isBack: false
    }
  },
  created: function () {
    const id = this.$route.query.id
    const data_type = this.$route.query.data_type
    if (!id) {
      return
    }
    this.isBack = this.$route.query.is_back
    request({
      url: 'feedback/progress',
      data: { id: id, data_type }
    }).then(r => {
      if (r.data.data.feedback) {
        this.order = r.data.data.feedback
      } else {
        this.order= r.data.data.order
      }
      this.logList = r.data.data.log_list.data
      this.loading = false
    })
  },
  methods: {
  }
}
</script>
<style lang="less">
.ant-drawer-body{
  height: 200px;
  overflow: auto;
  padding: 12px;
}
.profile_container{
  padding: 10px;
  img{
    max-width: 100% !important;
  }
  p{
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.container{
  padding: 8px;
  padding-top: 58px;
  padding-bottom: 60px;
  .product_container{
    font-size: 16px;
    margin-bottom: 8px;
    padding: 8px;
    background-color: #fff;
    img{
      width: 100%;
    }
  }
  .desc_container{
    margin-bottom: 8px;
    background-color: #fff;
    .title{
      border-left: 4px solid #3263f2;
      padding-left: 10px;
      height: 44px;
      line-height: 44px;
      font-size: 18px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #e6e6e6;
    }
    .desc_item{
      display: flex;
      padding: 10px 0 10px 14px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      .name{
        color: #999;
        width: 80px;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 8px;
      }
      .value{
        color: #222;
        flex: 1;
        overflow: hidden;
        word-wrap:break-word; white-space:normal; word-break:break-all;
      }
    }
  }
  .user_container{
    padding: 14px;
    display: flex;
    justify-content: space-between;
    .user_info{
      display: flex;
      font-size: 18px;
      img{
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 2px;
      }
    }
    .job{
      padding: 0 10px;
      font-size: 12px;
      background-color: #eeeffe;
      display: inline-flex;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 10px;
      color: #3263f2;
    }
  }
}
.feedback_type_container{
  display: flex;
  flex-wrap: wrap;
  div {
    background-color: #3263f2;
    color: #fff;
    border-radius: 10px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    padding: 0 16px;
    margin-right: 6px;
    margin-bottom: 10px;
  }
}
.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240,242,245, 1);
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.timeline_container{
  background-color: #fff;
  padding: 10px;
  padding-top: 18px;
  color: #222;
  p{
    margin-bottom: 0;
  }
}
/deep/ .ant-timeline-item-last{
  padding-bottom: 0
}
</style>
